import React, { useEffect } from 'react'

import Sidebar from '@cms/core/components/Sidebar'
import Header from '@cms/core/components/Header'

import { MainLayout, MainLayoutInner } from './styles'
import Content from '../Content'
import { useRootModule } from '../AppProvider/Hooks/useRootModule'
import { AppMainContent } from '../AppMainContent'
import { ScrollProvider } from '../AppMainContent/context'
import { ItemType, MenuItemType } from 'antd/es/menu/interface'

interface AppLayoutProps {
    children?: React.ReactNode
    rootModule?: string
    HeaderAfterModuleComponent?: React.ComponentType
    HeaderBeforeModuleComponent?: React.ComponentType
    headerMenuItems?: ItemType<MenuItemType>[]
}

const AppLayout = function ({
    children,
    rootModule,
    HeaderAfterModuleComponent,
    HeaderBeforeModuleComponent,
    headerMenuItems
}: AppLayoutProps) {
    const { setRootModule } = useRootModule()

    useEffect(() => {
        if (rootModule) setRootModule(rootModule)
    }, [rootModule])

    return (
        <MainLayout>
            <Header
                AfterModuleComponent={HeaderAfterModuleComponent}
                BeforeModuleComponent={HeaderBeforeModuleComponent}
                menuItems={headerMenuItems}
            />
            <MainLayoutInner>
                <Sidebar />
                <ScrollProvider>
                    <AppMainContent>
                        <Content>{children}</Content>
                    </AppMainContent>
                </ScrollProvider>
            </MainLayoutInner>
        </MainLayout>
    )
}

export default AppLayout
