import { COLORS, SIZES } from '@cms/core/themes'
import styled from 'styled-components'

export const Wrapper = styled.div`
    height: 220px;
    background: ${COLORS.bgLight};
    border-radius: ${SIZES.borderRadiusMD};
    width: 100%;
    padding: 15px;
`
export const WrapperSpeakers = styled.div`
    max-height: 150px;
    padding-left: 10px;
    overflow: hidden;
    overflow-y: auto;
`
export const Speaker = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`

export const TextWrapper = styled.div`
    margin-left: 8px;
    h4 {
        font-weight: 600;
        margin-bottom: 3px;
    }
    p {
        margin-bottom: 0;
    }
    span {
        font-weight: 600;
    }
`

export const SpeakerImg = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
`

export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`

