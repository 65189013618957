import React from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'

import { StopOutlined } from '@ant-design/icons'

import {
    CrudColumns,
    CrudList,
    CrudLinks,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import CustomersModal from './CustomersModal'

import { GET_CUSTOMER_LIST_ORDENABLE } from '@cms/core/graphql/queries/customer'
import {
    DELETE_CUSTOMER,
    UPDATE_CUSTOMER
} from '@cms/core/graphql/mutations/customer'
import { removeFromList } from '@cms/core/graphql/utils'
import { Button } from 'antd'

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        render: (value, obj) => {
            return (
                <CrudColumns
                    title={obj.node.name}
                    text={[obj.node.db]}
                    onlyText
                />
            )
        }
    },
    {
        title: 'Permissões',
        dataIndex: [''],
        with: '200px',
        render: (value, obj) => {
            const { events, team } = obj.node.permissions || {}
            return (
                <CrudColumns
                    text={[
                        events && (
                            <>
                                <strong>Eventos: </strong>
                                {events.used} de{' '}
                                {events.amount === -1 ? '∞' : events.amount}
                            </>
                        ),
                        ,
                        team && (
                            <>
                                <strong>Time: </strong>
                                {team.used} de{' '}
                                {team.amount === -1 ? '∞' : team.amount}
                            </>
                        )
                    ]}
                    onlyText
                />
            )
        }
    },
    {
        title: 'Plano',
        dataIndex: [''],
        with: '200px',
        render: (value, obj) => {
            return (
                <>
                    <strong>Plano: </strong>
                    {obj.node.billingPlan?.name}
                </>
            )
        }
    },
    {
        title: 'Status',
        dataIndex: [''],
        with: '200px',
        render: (value, obj) => {
            return (
                <>
                    <strong>Status: </strong>
                    {obj.node.blocked ? 'Inativo' : 'Ativo'}
                </>
            )
        }
    }
]

const orderByFields = [
    { label: 'Nome', value: 'name' },
    { label: 'Data criação', value: 'createdAt' }
]

const path = `/clientes`

const CustomersList = () => {
    const client = useApolloClient()

    const query = useQuery(GET_CUSTOMER_LIST_ORDENABLE, {
        variables: { first: 25, orderBy: { name: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_CUSTOMER, {
        update: removeFromList({ list: 'customers', Type: 'Customer' })
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(CustomersModal, path, {})
    }, [])

    if (query.error) return <div>Error</div>

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        align: 'right',
        render: (_, record) => {
            const { id, blocked } = record.node

            const onClick = async () => {
                const { errors } = await client.mutate({
                    mutation: UPDATE_CUSTOMER,
                    variables: { id, blocked: !blocked }
                })

                query.refetch()
            }

            return (
                <Button
                    type="primary"
                    icon={<StopOutlined />}
                    onClick={onClick}
                >
                    {blocked ? 'Desbloquear' : 'Bloquear'}
                </Button>
            )
        }
    }

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Cliente',
                    subTitle: 'Adicione e gerencie clientes',
                    buttons: [
                        {
                            children: 'Novo Cliente',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'customers'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                actionsExtra={actionsExtra}
                {...query}
            />
        </>
    )
}

export default CustomersList
