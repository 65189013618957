import { gql } from '@apollo/client'

export const MediaData = gql`
    fragment MediaData on Media {
        _id
        filename
        url
    }
`

export const IntegrationData = gql`
    fragment IntegrationData on Integration {
        id
        name
        category
        type
        active
        config
        privateConfig
        appType
        logs(filter: { status: FAIL }) {
            totalCount
        }
    }
`

export const UserData = gql`
    fragment UserData on User {
        id
        name
        email
        phone
        company
        jobTitle
        department
        role
        profile {
            id
            name
            modules
            fullAccess
        }
        avatar {
            ...MediaData
        }
        watchPlan {
            id
            name
        }
    }
    ${MediaData}
`

export const UserProfileData = gql`
    fragment UserProfileData on UserProfile {
        id
        name
        fullAccess
        modules
        createdAt
        updatedAt
    }
`

export const CustomerPermission = gql`
    fragment CustomerPermission on CustomerPermissionsConfig {
        amount
        config
        used
    }
`

export const CustomerData = gql`
    fragment CustomerData on Customer {
        id
        name
        email
        cnpj
        emailableApiKey
        db
        master
        enableMobile
        logo {
            ...MediaData
        }
        modules
        permissions {
            events {
                ...CustomerPermission
            }
            team {
                ...CustomerPermission
            }
        }
        blocked
        billingPlan {
            id
            name
            fullAccess
            modules
        }
    }
    ${MediaData}
    ${CustomerPermission}
`
