import { gql } from '@apollo/client'
import {
    MediaData,
    UserData,
    IntegrationData
} from '@cms/core/graphql/fragments'

export * from '@cms/core/graphql/fragments'

export const NetworkData = gql`
    fragment NetworkData on Network {
        id
    }
`

export const NotificationData = gql`
    fragment NotificationData on Notification {
        id
        title
        message
        link
        button
        createdAt
        integrations {
            ...IntegrationData
        }
    }
    ${IntegrationData}
`

export const TalkNotificationData = gql`
    fragment TalkNotificationData on TalkNotification {
        id
        title
        message
        image {
            url
        }
        link
        buttonText
        chat
        popup
        createdAt
        updatedAt
        active
        integrations {
            ...IntegrationData
        }
    }
    ${IntegrationData}
`

export const UserReportData = gql`
    fragment UserReportData on UserReport {
        id
        reporter {
            ...UserData
        }
        reported {
            ...UserData
        }
        messageContent
        reason
        solved
    }

    ${UserData}
`

export const ChatData = gql`
    fragment ChatData on Chat {
        id
        user {
            ...UserData
        }
        owner {
            ...UserData
        }
        type
        link
    }
`

export const CategoryData = gql`
    fragment CategoryData on Category {
        id
        name
        brands {
            edges {
                node {
                    id
                    name
                }
            }
        }
        type
        description
        icon
        summary
        talksTotalCount
        createdAt
        updatedAt
    }
`

export const BrandData = gql`
    fragment BrandData on Brand {
        id
        name
        logo {
            ...MediaData
        }
        image {
            ...MediaData
        }
        archive {
            ...MediaData
        }
        description
        contact
        aboutUs
        email
        site
        social {
            type
            url
        }
        resources {
            title
            link
        }
        actions {
            name
            url
            size
        }
        talkStand {
            url
            type
        }
        priority
        featured
        tags
        createdAt
        updatedAt
        chiefs {
            id
            name
        }
        sponsor {
            id
            name
        }
        plan {
            id
            name
            hostType
        }
        institutionalVideos {
            url
        }
        page {
            enabled
            title
            slug
            image {
                url
            }
        }
        draft {
            status
            data {
                logo {
                    ...MediaData
                }
                image {
                    ...MediaData
                }
                archive {
                    ...MediaData
                }
                description
                contact
                aboutUs
                email
                site
                social {
                    type
                    url
                }
                resources {
                    title
                    link
                }
                actions {
                    name
                    url
                    size
                }
                talkStand {
                    url
                    type
                }
                featured
                tags
                chiefs {
                    id
                    name
                }
                institutionalVideos {
                    url
                }
            }
        }
    }

    ${MediaData}
`

export const BannerData = gql`
    fragment BannerData on Banner {
        id
        link
        desktop {
            ...MediaData
        }
        mobile {
            ...MediaData
        }
        talks {
            edges {
                node {
                    id
                    title
                }
            }
        }
        tickets {
            id
            name
        }
        integrations {
            ...IntegrationData
        }
        pages
        active
        createdAt
        updatedAt
    }

    ${MediaData}
    ${IntegrationData}
`

export const SpeakerData = gql`
    fragment SpeakerData on Speaker {
        id
        image {
            ...MediaData
        }
        name
        firstName
        lastName
        email
        bio
        company {
            name
            image {
                ...MediaData
            }
        }
        office
        links {
            type
            url
        }
        user {
            id
            name
        }
        tags
        featured
        priority
        createdAt
        updatedAt
        page {
            enabled
            title
            slug
            image {
                ...MediaData
            }
        }
    }

    ${MediaData}
`

export const TalkData = gql`
    fragment TalkData on Talk {
        id
        type
        priority
        image {
            ...MediaData
        }
        imageLive {
            ...MediaData
        }
        imageLiveApp {
            ...MediaData
        }
        featureImage {
            ...MediaData
        }
        title
        description
        date
        duration
        status
        isLive
        featured
        hiddenInHome
        resources {
            title
            link
        }
        reports {
            views
        }
        video {
            url
            player
        }
        videos {
            locale
            video {
                url
                player
            }
        }
        videoWeb {
            url
        }
        iframe {
            url
            name
        }
        speakers {
            edges {
                node {
                    id
                    name
                    company {
                        name
                    }
                    image {
                        ...MediaData
                    }
                    office
                }
            }
        }
        categories {
            edges {
                node {
                    id
                    name
                }
            }
        }
        brands {
            edges {
                node {
                    id
                }
            }
        }
        sponsorTmp {
            id
            name
        }
        stage {
            id
            title
        }
        createdAt
        reactions {
            likes
            loves
            laughing
            surprise
            sad
            angry
        }
        updatedAt
        startDate
        endDate
        duration
        tags
        hostType
        location {
            id
            name
        }
        rating {
            total
            average
            one
            two
            three
            four
            five
        }
    }

    ${MediaData}
`

export const ViralCampaignData = gql`
    fragment ViralCampaignData on ViralCampaign {
        id
        name
        dailyGoals {
            referrals
            subscribers
        }
        referrerRewards {
            _id
            name
            indications
            points
            description
            media {
                ...MediaData
            }
            useEmail
            emailTemplate {
                nameFrom
                emailFrom
                subject
                replyTo
                sayHello
                content
                thankYouMessage
                media {
                    ...MediaData
                }
                sizeMedia
                button {
                    text
                    link
                    backgroundColor
                    textColor
                }
            }
        }
        topRewards {
            _id
            name
            indications
            points
            description
            media {
                ...MediaData
            }
        }
        indicatedRewards {
            _id
            name
            description
            media {
                ...MediaData
            }
            useEmail
            emailTemplate {
                nameFrom
                emailFrom
                subject
                replyTo
                sayHello
                content
                thankYouMessage
                media {
                    ...MediaData
                }
                sizeMedia
                button {
                    text
                    link
                    backgroundColor
                    textColor
                }
            }
        }
        social {
            facebook {
                active
                text
            }
            whatsapp {
                active
                text
            }
            linkedin {
                active
                text
            }
            email {
                active
                text
            }
            twitter {
                active
                text
            }
        }
        messages {
            welcome {
                active
                emailTemplate {
                    nameFrom
                    emailFrom
                    subject
                    replyTo
                    sayHello
                    content
                    thankYouMessage
                    media {
                        ...MediaData
                    }
                    sizeMedia
                    button {
                        text
                        link
                        backgroundColor
                        textColor
                    }
                }
            }
            notificationReferrer {
                active
                emailTemplate {
                    nameFrom
                    emailFrom
                    subject
                    replyTo
                    sayHello
                    content
                    thankYouMessage
                    media {
                        ...MediaData
                    }
                    sizeMedia
                    button {
                        text
                        link
                        backgroundColor
                        textColor
                    }
                }
            }
        }
    }

    ${MediaData}
`

export const EngagementCampaignData = gql`
    fragment EngagementCampaignData on EngagementCampaign {
        id
        name
        rewards {
            _id
            name
            points
            description
            media {
                ...MediaData
            }
            useEmail
            emailTemplate {
                nameFrom
                emailFrom
                subject
                replyTo
                sayHello
                content
                thankYouMessage
                media {
                    ...MediaData
                }
                sizeMedia
                button {
                    text
                    link
                    backgroundColor
                    textColor
                }
            }
        }
        triggers {
            _id
            event
            points
            description
        }
    }
    ${MediaData}
`

export const SiteData = gql`
    fragment SiteData on Site {
        id
        domain
        slug
        published
        params
        draft
        data
        createdAt
        updatedAt
    }
`

export const CustomFieldData = gql`
    fragment CustomFieldData on CustomField {
        id
        name
        label
        type
        options {
            label
            value
        }
    }
`

export const CheckoutConfigData = gql`
    fragment CheckoutConfigData on CheckoutConfig {
        redirectUrl
        infos
        info
        theme
        scripts {
            header
            bodyStart
            bodyEnd
        }
        backgroundImage {
            ...MediaData
        }
        loginBackgroundImage {
            ...MediaData
        }
    }

    ${MediaData}
`

export const EventCheckoutConfigData = gql`
    fragment EventCheckoutConfigData on Event {
        redirectUrl
        info
        theme
        scripts {
            header
            bodyStart
            bodyEnd
        }
        backgroundImage {
            ...MediaData
        }
        loginBackgroundImage {
            ...MediaData
        }
    }

    ${MediaData}
`

export const EventData = gql`
    fragment EventData on Event {
        id
        type
        hostType
        name
        isPublished
        description
        addressName
        address
        eventInfos
        checkoutConfig {
            redirectUrl
            info
        }
        helpMail
        theme {
            primaryColor
            highlight
            nearWhite
            dark
        }
        themeV2
        startDate
        endDate
        featured
        slogan
        tag
        highlightSlogan
        fields {
            name
            label
        }
        talksStats {
            total
            average
        }
        site {
            ...SiteData
        }
        logo {
            ...MediaData
        }
        backgroundImageMobile {
            ...MediaData
        }
        backgroundImageDesktop {
            ...MediaData
        }
        backgroundImageHome {
            ...MediaData
        }
        scheduleImage {
            ...MediaData
        }
        scheduleImageMobile {
            ...MediaData
        }
        viralCampaign {
            ...ViralCampaignData
        }
        engagementCampaign {
            ...EngagementCampaignData
        }
        domain {
            name
            validated
            error
        }
        streamingDomain {
            name
            validated
            error
        }
        checkoutDomain {
            name
            validated
            error
        }
        certificate {
            enabled
            bgImage {
                ...MediaData
            }
            textColor
            duration
        }
        scripts {
            header
            bodyStart
            bodyEnd
        }
        terms {
            url
            text
        }
        editions {
            id
            name
        }
        hashtags
        players {
            active
            name
            config
        }
        integrations {
            active
            name
            config
        }
        showNumberOfRegistrations
        showReactions
        showNumberOfSpeakers
        showDates
        showNumberOfViewers
        showLiveSponsor
        showSocialAuth
        showLiveChat
        allowStreamingRegistration
        translation {
            languages
        }
        showSpeaperkSocialInfo
        chatConfig {
            active
            slowMode {
                active
                duration
            }
            spanMode {
                active
                duration
            }
        }
        menus {
            main {
                name
                label
                url
                icon
                visible
            }
            user {
                name
                label
                url
                icon
                visible
            }
        }
        home {
            sections {
                type
                name
                active
                config
            }
        }
        liveTagText
        footerConfig {
            background
            links {
                label
                url
            }
            social {
                type
                url
            }
        }
    }

    ${SiteData}
    ${MediaData}
    ${ViralCampaignData}
    ${EngagementCampaignData}
`

export const BillingPlanData = gql`
    fragment BillingPlanData on BillingPlan {
        id
        name
        modules
        fullAccess
        createdAt
        updatedAt
    }
`

export const FormDataData = gql`
    fragment FormDataData on FormData {
        id
        uuid
        data
        createdAt
    }
`

export const BrandPlanData = gql`
    fragment BrandPlanData on BrandPlan {
        id
        name
        type
        priority
        sponsorLink
        sponsorPage
        hostType
        assets {
            name
            description
        }
    }
`

export const LocationData = gql`
    fragment LocationData on Location {
        id
        name
        event {
            id
        }
    }
`

export const EventFormFieldData = gql`
    fragment EventFormFieldData on EventFormField {
        name
        label
        type
        encrypted
        options
        config
    }
`

export const EventFormEmailButtonData = gql`
    fragment EventFormEmailButtonData on EventFormEmailButton {
        text
        link
        backgroundColor
        textColor
    }
`

export const EventFormEmailTemplateData = gql`
    fragment EventFormEmailTemplateData on EventFormEmailTemplate {
        nameFrom
        emailFrom
        subject
        replyTo
        sayHello
        content
        thankYouMessage
        button {
            ...EventFormEmailButtonData
        }
        sizeMedia
        media {
            ...MediaData
        }
    }
    ${EventFormEmailButtonData}
    ${MediaData}
`

export const EventFormEmailData = gql`
    fragment EventFormEmailData on EventFormEmail {
        active
        template {
            ...EventFormEmailTemplateData
        }
    }
    ${EventFormEmailTemplateData}
`

export const EventFormData = gql`
    fragment EventFormData on EventForm {
        id
        type
        name
        config
        emailExternalValidation
        botProtection
        defaultTicket
        fields {
            ...EventFormFieldData
        }
        integrations {
            ...IntegrationData
        }
        email {
            ...EventFormEmailData
        }
    }
    ${IntegrationData}
    ${EventFormFieldData}
    ${EventFormEmailData}
`

export const TicketData = gql`
    fragment TicketData on Ticket {
        id
        name
        active
        value
        currency
        quantity
        maxPerPurchase
        unlimited
        accessDuration
        lifetimeAccess
        modules
        benefits
        description
        version
        contentBlockedConfig {
            image {
                ...MediaData
            }
            link
        }
        contentBlockedByTags
        contentHiddenByTags
        accessRestrictions {
            talks {
                id
                duration
                title
                type
                status
            }
            categories {
                id
                name
                type
            }
            speakers {
                id
                name
            }
            collectionCategories {
                id
                name
            }
        }
        integrations {
            ...IntegrationData
        }
    }

    ${IntegrationData}
    ${MediaData}
`

export const TicketCouponData = gql`
    fragment TicketCouponData on TicketCoupon {
        id
        code
        description
        active
        discountPercentage
        event {
            id
        }
    }
`

export const TicketOwnershipsData = gql`
    fragment TicketOwnershipsData on TicketOwnership {
        id
        code
        event {
            id
            name
        }
        user {
            id
            name
        }
        buyer {
            id
            name
        }
        ticket {
            id
            name
        }
        transferable
        createdAt
        updatedAt
    }
`

export const OrderData = gql`
    fragment OrderData on Order {
        id
        status
        eventRegistration {
            id
        }
        ticket {
            id
        }
        humanizedTimeLeft
        createdAt
    }
`

export const ReportData = gql`
    fragment ReportData on Report {
        id
        type
        status
        downloadLink
        progress
        event {
            id
            name
        }
    }
`

export const CustomTrackerData = gql`
    fragment CustomTrackerData on CustomTracker {
        id
        name
        description
        eventName
        eventMessage
        active
    }
`

export const FaqTopicData = gql`
    fragment FaqTopicData on FaqTopic {
        id
        name
        description
        createdAt
        updatedAt
    }
`

export const FaqQuestionData = gql`
    fragment FaqQuestionData on FaqQuestion {
        id
        question
        answer
        event {
            id
            name
        }
        topic {
            id
            name
        }
        createdAt
        updatedAt
    }
`

export const CollectionCategoryData = gql`
    fragment CollectionCategoryData on CollectionCategory {
        id
        name
        description
        hasAction
        showPreEvent
        tags
        event {
            id
            name
        }
        createdAt
        updatedAt
    }
`

export const CollectionItemData = gql`
    fragment CollectionItemData on CollectionItem {
        id
        title
        image {
            ...MediaData
        }
        action {
            label
            url
        }
        category {
            id
            name
        }
        event {
            id
            name
        }
        tags
        createdAt
        updatedAt
    }

    ${MediaData}
`

export const EventEditionData = gql`
    fragment EventEditionData on EventEdition {
        id
        name
        events {
            id
            name
            logo {
                url
            }
        }
        createdAt
        updatedAt
    }
`

export const QuizData = gql`
    fragment QuizData on Quiz {
        id
        question
        answers
        talk
        duration
        start
        status
        startAt
        endAt
        stats {
            answer
            quantity
        }
    }
`

export const EventRegistrationData = gql`
    fragment EventRegistrationData on EventRegistration {
        id
        createdAt
        data
        referral {
            source
        }
        ticketOwnership {
            ...TicketOwnershipsData
        }
        user {
            id
            avatar {
                ...MediaData
            }
            name
            email
            phone
            createdAt
        }
    }

    ${MediaData}
    ${TicketOwnershipsData}
`

export const IntegrationLogData = gql`
    fragment IntegrationLogData on IntegrationLog {
        id
        data
        status
        integration {
            name
            type
        }
        response
        createdAt
        updatedAt
    }
`
