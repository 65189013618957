import React from 'react'
import { Tag, Row, Space, Dropdown, Menu } from 'antd'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import { useParams, Link } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import TalksModal from './TalksModal'
import TalkControlPanel from './TalkControlPanel'

import {
    GET_STAGES,
    GET_TALK_LIST_ORDERABLE
} from '@cms/events/graphql/queries/talk'
import { DELETE_TALK, UPDATE_TALK } from '@cms/events/graphql/mutations/talk'
import { removeFromList } from '@cms/core/graphql/utils'
import { CreateDashboardRoute } from '@cms/events/components/DashboardTab/utils'
import TalkDashboard from './Dashboard'

const type = 'LIVE'

const TalksList = () => {
    const { eventId } = useParams()

    const { data: stageData } = useQuery(GET_STAGES, {
        variables: {
            eventId,
            orderBy: { createdAt: 'ASC' }
        }
    })

    const query = useQuery(GET_TALK_LIST_ORDERABLE, {
        variables: { first: 25, eventId, type, orderBy: { startDate: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_TALK, {
        update: removeFromList({ list: 'talks', Type: 'Talk' })
    })

    const [updateTalk] = useMutation(UPDATE_TALK)

    const path = `/evento/${eventId}/palestras`

    const DashboardRoute = useCreation(() => {
        return CreateDashboardRoute(TalkDashboard, path, { eventId })
    }, [eventId])

    const ModalRoutes = useCreation(() => {
        const RoutersControlPanel = CreateModalRoutes(TalkControlPanel, path, {
            eventId,
            label: 'Enquetes'
        })
        const Routers = CreateModalRoutes(TalksModal, path, {
            eventId,
            type,
            label: 'Palestra'
        })
        return () => (
            <>
                <Routers />
                <RoutersControlPanel.Custom
                    path={`${path}/:id/painel`}
                    render={() => (
                        <TalkControlPanel
                            eventId={eventId}
                            path={path}
                            type={type}
                        />
                    )}
                />
            </>
        )
    }, [eventId])

    const stages =
        stageData?.talks?.edges?.map(({ node }) => ({
            label: node.title,
            value: node.id
        })) || []

    const columns = [
        {
            title: 'Título',
            dataIndex: ['node', 'title'],
            sorter: 'title',
            render: (_, obj) => {
                return <CrudColumns title={obj.node.title} onlyText />
            }
        },
        {
            title: 'Data',
            dataIndex: ['node', 'date'],
            sorter: 'startDate',
            with: 200,
            render: (_, obj) => {
                const date = obj.node.date
                const start = dayjs(new Date(date)).format('D MMM [-] H:mm')
                const end = dayjs(new Date(obj.node.endDate)).format('H:mm')
                const fullDate = start + ' às ' + end
                const speakersCount = obj.node.speakers
                    ? obj.node.speakers.edges.length
                    : '0'

                return (
                    <CrudColumns
                        text={[
                            fullDate ? fullDate : '---',
                            `Palestrantes: ${speakersCount}`
                        ]}
                        onlyText
                    />
                )
            }
        },
        {
            title: 'Palco',
            dataIndex: [''],
            with: stages?.length ? 150 : 0,
            render: (value, obj) => {
                const stage = obj.node?.stage?.title || null
                if (!stages?.length) return null
                return (
                    <Row style={{ paddingTop: '8px' }}>
                        <span>{stage ? stage : 'Não definido'}</span>
                    </Row>
                )
            }
        },
        {
            title: 'Contém vídeo cadastrado?',
            dataIndex: ['node', 'video'],
            width: stages?.length ? 220 : 150,
            align: 'center',
            render: (video, obj) => {
                const hasVideos = [
                    video,
                    ...(obj.node.videos || []).map((o) => o.video)
                ].some((o) => o && o.url)
                return (
                    <Row style={{ paddingTop: '8px' }}>
                        {hasVideos ? (
                            <Tag color="#7F44FF">Sim</Tag>
                        ) : (
                            <Tag color="#646a6a">Não</Tag>
                        )}
                    </Row>
                )
            }
        }
    ]

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        width: 150,
        align: 'right',
        render: (_, record) => {
            const id = record.node.id

            const setToOnDemand = async (e) => {
                const variables = { id: id, type: 'ONDEMAND', event: eventId }

                await updateTalk({ variables })
            }

            const menu = (
                <Menu>
                    <Menu.Item key="1">
                        <Link to={path + '/' + id + '/painel'}>
                            Painel de Controle
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="2">
                        <a target="_blank" onClick={setToOnDemand}>
                            Mover para On Demand
                        </a>
                    </Menu.Item>
                </Menu>
            )

            return (
                <Space>
                    <Dropdown.Button type="primary" overlay={menu}>
                        <Link to={path + '/' + id + '/dashboard'}>
                            Dashboard
                        </Link>
                    </Dropdown.Button>
                </Space>
            )
        }
    }

    // const orderByFields = [
    //     { label: 'Nome', value: 'name' },
    //     { label: 'Data criação', value: 'createdAt' },
    //     { label: 'Data início', value: 'startDate' }
    // ]

    if (query.error) return null

    return (
        <>
            <DashboardRoute />
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Palestras',
                    subTitle: 'Adicione e gerencie as palestras',
                    countNames: {
                        singular: 'Palestra',
                        plural: 'Palestras'
                    },
                    buttons: [
                        {
                            children: [
                                { label: 'Todas', value: 'ALL' },
                                { label: 'Transmitindo', value: 'STARTED' },
                                { label: 'Finalizada', value: 'FINISHED' },
                                { label: 'Aguardando', value: 'WAITING' }
                            ],
                            type: 'select',
                            action: (value) => {
                                if (value !== 'ALL') {
                                    query.refetch({ status: value })
                                } else {
                                    query.refetch({ status: undefined })
                                }
                            }
                        },
                        {
                            children: [
                                { label: 'Palcos', value: 'ALL' },
                                ...stages
                            ],
                            type: 'select',
                            action: (value) => {
                                if (value !== 'ALL') {
                                    query.refetch({ stage: value })
                                } else {
                                    query.refetch({ stage: undefined })
                                }
                            }
                        },
                        {
                            children: 'Nova Palestra',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                queryName={'talks'}
                onDelete={deleteData}
                search={true}
                module={'palestras'}
                actionsExtra={actionsExtra}
                showHeader
                {...query}
            />
        </>
    )
}

export default TalksList
