import { useQuery } from '@apollo/client'
import { ButtonLink, ButtonReturn } from '@cms/events/components/Buttons'
import Content from '@cms/core/components/Content'
import { CreateModalRoutes } from '@cms/core/components/Crud'
import Reactions from '@cms/events/components/Dashboard/Reactions'
import Loader from '@cms/core/components/Loader'
import {
    GET_EVENT,
    GET_EVENT_STREAMING_TOKENS
} from '@cms/events/graphql/queries/event'
import { GET_TALK } from '@cms/events/graphql/queries/talk'
import { useCreation } from '@umijs/hooks'
import { Button, Col, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import NotificationTalkModal from '../../Notifications/NotificationTalkModal'
import TalkAdvertising from './Advertising'
import Avaliation from './Avaliation'
import Chat from './Chat'
import DisplayTime from './Infos/DisplayTime'
import PeakTime from './Infos/PeakTime'
import TalkViews from './Infos/TalkViews'
import TotalViews from './Infos/TotalViews'
import Player from './Player'
import Speakers from './Speakers'
import {
    FullScreenBtn,
    InfoCard,
    TabPane,
    Tabs,
    TalkConfigWrapper,
    WrapperButtons,
    WrapperInfo
} from './styles'
import SurveyModal from './SurveyModal'
import SurveyReport from './SurveyReport'
import Surveys from './Surveys'
import TalksModal from '../TalksModal'
import { FullScreen } from '@chiragrupani/fullscreen-react'
import { FullscreenOutlined } from '@ant-design/icons'

const { Text } = Typography

const ModalContent = ({ eventId, type, params }) => {
    const { id } = useParams()
    const [token, setToken] = useState()
    const [isFullScreen, setIsFullScreen] = useState(false)

    const { data: eventData, loading } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    const { data: TalkData, refetch } = useQuery(GET_TALK, {
        variables: { id },
        fetchPolicy: 'no-cache',
        returnPartialData: true
    })

    const { data: tokensData } = useQuery(GET_EVENT_STREAMING_TOKENS, {
        variables: {
            event: eventId
        }
    })

    useEffect(() => {
        if (tokensData?.eventStreamingTokens) {
            const { token } = tokensData?.eventStreamingTokens
            setToken(token)
        }
    }, [tokensData])

    const path = `/evento/${eventId}/palestras/${id}/painel`

    const ModalSurveyRoute = useCreation(() => {
        const path = `/evento/${eventId}/palestras/${id}/painel/survey`
        return CreateModalRoutes(SurveyModal, path, { id })
    }, [eventId])

    const ModalTalkNotificationRoute = useCreation(() => {
        const path = `/evento/${eventId}/palestras/${id}/painel/publicidade/notificacoes`
        return CreateModalRoutes(NotificationTalkModal, path, {
            talkId: id,
            eventId
        })
    }, [eventId])

    const ModalRoutes = useCreation(() => {
        const Routers = CreateModalRoutes(Surveys, path, {
            eventId
        })

        const TalkRouters = CreateModalRoutes(
            TalksModal,
            `/evento/${eventId}/palestras/:id/painel`,
            {
                eventId,
                type: 'LIVE',
                label: 'Palestra'
            },
            {
                ignoreId: true,
                returnPath: `/evento/${eventId}/palestras/${id}/painel`
            }
        )

        return () => (
            <>
                <Routers.Custom
                    path={`${path}/survey`}
                    render={() => (
                        <Surveys eventId={eventId} path={path} talkId={id} />
                    )}
                />
                <Routers.Custom
                    path={`${path}/publicidade`}
                    render={() => (
                        <TalkAdvertising
                            eventId={eventId}
                            path={path}
                            talk={id}
                        />
                    )}
                />
                <Routers.Custom
                    path={`${path}/survey/:surveyid/report`}
                    render={() => (
                        <SurveyReport eventId={eventId} path={path} />
                    )}
                />
                <TalkRouters />
            </>
        )
    }, [eventId, id])

    if (!eventData) return null

    return (
        <FullScreen
            isFullScreen={isFullScreen}
            onChange={(isFullScreen) => {
                setIsFullScreen(isFullScreen)
            }}
        >
            <TalkConfigWrapper>
                <ModalSurveyRoute />
                <ModalRoutes />
                <ModalTalkNotificationRoute />
                <Layout
                    style={{
                        minHeight: 'fit-content',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        display: 'flex'
                    }}
                >
                    {!loading ? (
                        <Content
                            style={{
                                padding: 0,
                                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                                marginBottom: '0',
                                flex: 1,
                                overflow: 'hidden'
                            }}
                        >
                            <Tabs
                                defaultActiveKey="1"
                                scrollY={500}
                                style={{ height: '100%' }}
                                tabBarExtraContent={{
                                    left: (
                                        <ButtonReturn
                                            path={`/evento/${eventId}/palestras/`}
                                        />
                                    ),
                                    right: (
                                        <FullScreenBtn>
                                            {!isFullScreen ? (
                                                <FullscreenOutlined
                                                    onClick={() =>
                                                        setIsFullScreen(true)
                                                    }
                                                />
                                            ) : (
                                                <FullscreenOutlined
                                                    onClick={() =>
                                                        setIsFullScreen(false)
                                                    }
                                                />
                                            )}
                                        </FullScreenBtn>
                                    )
                                }}
                            >
                                <TabPane tab={TalkData?.talk.title} key="1">
                                    <Row gutter={[24, 24]}>
                                        <Col
                                            span={14}
                                            style={{
                                                justifyContent: 'space-between',
                                                display: 'flex',
                                                minWidth: '100%',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <WrapperButtons>
                                                <Button>
                                                    <Link to={path + '/survey'}>
                                                        Gerenciar Enquete
                                                    </Link>
                                                </Button>
                                                <Button>
                                                    <Link
                                                        to={
                                                            path +
                                                            '/publicidade'
                                                        }
                                                    >
                                                        Publicidade
                                                    </Link>
                                                </Button>
                                                <ButtonLink
                                                    to={path + '/editar'}
                                                    path={'editar'}
                                                    text={'Editar'}
                                                />
                                            </WrapperButtons>
                                            {TalkData?.talk && (
                                                <WrapperInfo>
                                                    <TotalViews
                                                        talk={TalkData?.talk.id}
                                                        eventId={eventId}
                                                    />
                                                    <DisplayTime
                                                        talk={TalkData?.talk.id}
                                                        eventId={eventId}
                                                    />
                                                    <PeakTime
                                                        talk={TalkData?.talk.id}
                                                        eventId={eventId}
                                                    />
                                                    <TalkViews
                                                        talk={TalkData?.talk.id}
                                                        event={eventId}
                                                    />
                                                </WrapperInfo>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row
                                        gutter={[16, 16]}
                                        style={{
                                            marginTop: '25px',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    >
                                        <Col
                                            span={7}
                                            style={{
                                                maxHeight: 'calc(100% - 80px)'
                                            }}
                                        >
                                            {token && (
                                                <Chat talk={id} token={token} />
                                            )}
                                        </Col>
                                        {TalkData?.talk && (
                                            <Col
                                                span={17}
                                                style={{
                                                    maxHeight:
                                                        'calc(100% - 80px)',
                                                    overflowY: 'auto',
                                                    overflowX: 'hidden'
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        marginBottom: '32px'
                                                    }}
                                                >
                                                    <Col span={24}>
                                                        <Player
                                                            talk={
                                                                TalkData?.talk
                                                            }
                                                            eventId={eventId}
                                                            refetch={refetch}
                                                            type={type}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row gutter={[32, 32]}>
                                                    <Col span={8}>
                                                        <Avaliation />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Reactions
                                                            reactions={
                                                                TalkData?.talk
                                                                    .reactions
                                                            }
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <Speakers
                                                            speakers={
                                                                TalkData?.talk
                                                                    .speakers
                                                                    .edges
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Content>
                    ) : (
                        <Loader />
                    )}
                </Layout>
            </TalkConfigWrapper>
        </FullScreen>
    )
}

export default ModalContent
