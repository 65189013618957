import { COLORS, SIZES } from '@cms/core/themes'
import ReactPlayer from 'react-player'
import styled, { css } from 'styled-components'

export const PlayerWrapper = styled.div`
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);
    background: ${COLORS.bgLight};
    border-radius: ${SIZES.borderRadiusMD};
    height: 650px;
`

export const Player = styled(ReactPlayer)``
export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`

