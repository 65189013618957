import { useQuery } from '@apollo/client'
import { Layout } from 'antd'
import _get from 'lodash/get'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Content from '@cms/core/components/Content'
import { CrudColumns, CrudList } from '@cms/core/components/Crud'
import {
    GET_EVENT_FORM,
    GET_EVENT_FORM_DATA
} from '@cms/events/graphql/queries/form'
import { formatField } from '@td/commons/fields/formatter'

import ReportButton from '../ReportButton'

const ModalContent = ({ eventId }) => {
    const { id } = useParams()
    const [eventData, setEventData] = useState()
    const [columns, setColumns] = useState()

    const query = useQuery(GET_EVENT_FORM_DATA, {
        variables: { formId: id, first: 25 }
    })

    const queryForm = useQuery(GET_EVENT_FORM, {
        variables: { formId: id }
    })

    useEffect(() => {
        if (query.data) {
            setEventData(query.data.eventFormsData.edges)
        }
    }, [query.data])

    useEffect(() => {
        if (queryForm.data) {
            const fields = queryForm.data.eventForm.fields

            const _columns = [
                {
                    title: 'Nome',
                    dataIndex: [''],
                    width: 350,
                    render: (value, obj) => {
                        return (
                            <CrudColumns
                                title={obj.node.user?.name}
                                image={obj.node.user?.avatar?.url}
                                text={[
                                    obj.node.user?.email,
                                    `Data de criação: ${dayjs(
                                        new Date(obj.node?.createdAt)
                                    ).format('DD MMM YYYY')}`
                                ]}
                            />
                        )
                    }
                },
                {
                    title: 'Origem',
                    dataIndex: ['node', 'context'],
                    width: 250,
                    render: (context, obj) => {
                        const url =
                            context?.url ||
                            _get(obj, 'node.data._context.origin', '')
                        const title =
                            context?.title ||
                            _get(obj, 'node.data._context.origin', '')

                        if (url) {
                            return (
                                <CrudColumns
                                    onlyText
                                    title="Origem"
                                    text={[
                                        <a href={url} target="_blank">
                                            {title}
                                        </a>
                                    ]}
                                />
                            )
                        }

                        return '---'
                    }
                }
            ]

            fields.forEach((field) => {
                if (field.type !== 'session') {
                    _columns.push({
                        title: field.label,
                        dataIndex: ['node', 'data', field.name],
                        width: 200,
                        render: (_value) => {
                            let value = formatField(field, _value)
                            return (
                                <CrudColumns
                                    onlyText
                                    title="field.label"
                                    text={[value]}
                                />
                            )
                        }
                    })
                }
            })

            setColumns(_columns)
        }
    }, [queryForm.data])

    if (!eventData && !query.loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%'
                }}
            >
                Nenhum dado encontrado
            </div>
        )
    }

    if (!columns) return null

    return (
        <Layout
            style={{
                height: '100vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}
        >
            <Content>
                <ReportButton eventId={eventId} formId={id} />
                <CrudList
                    header={{
                        title: 'Detalhe do formulário'
                    }}
                    style={{
                        height: '15vh'
                    }}
                    columns={columns}
                    queryName={'eventFormsData'}
                    hideAction={'all'}
                    scrollY={'91%'}
                    {...query}
                />
            </Content>
        </Layout>
    )
}

export default ModalContent
