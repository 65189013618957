import React from 'react'
import { Route } from 'react-router-dom'
import { CheckModuleByPath } from '@cms/core/components/CheckModule'

export const CreateModalRoutes = (
    Component,
    path,
    params = {},
    { ignoreId = false, returnPath } = {}
) => {
    const viewOrEditPath = ignoreId ? path : `${path}/:id`
    const RouterComponent = ({ allowed = true }) => (
        <>
            {allowed ? (
                <>
                    <Route
                        exact
                        path={`${path}/adicionar`}
                        render={() => (
                            <Component
                                path={returnPath || path}
                                params={params}
                                mode="write"
                            />
                        )}
                    />

                    <Route
                        exact
                        path={`${viewOrEditPath}/editar`}
                        render={() => (
                            <Component
                                path={returnPath || viewOrEditPath}
                                params={params}
                                mode="write"
                            />
                        )}
                    />
                </>
            ) : (
                <Route
                    exact
                    path={`${viewOrEditPath}/visualizar`}
                    render={() => (
                        <Component
                            path={returnPath || viewOrEditPath}
                            params={params}
                            mode="view"
                        />
                    )}
                />
            )}
        </>
    )

    RouterComponent.Custom = (props) => <Route {...props} />

    return RouterComponent
}
