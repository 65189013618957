import React from 'react'
import { IAppModule } from '@cms/core/types/app'
import { sidebarIcons } from '@cms/core/components/RemixIcons'

export const EventModules: IAppModule[] = [
    {
        name: 'Principal',
        icon: sidebarIcons.dashbord,
        module: 'events.event.dashboard',
        type: 'main',
        children: [
            {
                name: 'Conversões',
                path: '/evento/:eventId/dashboard',
                exact: true,
                module: 'events.event.dashboard.conversions'
            },
            {
                name: 'Visão Geral',
                path: '/evento/:eventId/dashboard/visao-geral',
                module: 'events.event.dashboard.general'
            },
            {
                name: 'Tempo Real',
                path: '/evento/:eventId/dashboard/tempo-real',
                module: 'events.event.dashboard.real-time'
            },
            {
                name: 'Patrocinadores',
                path: '/evento/:eventId/dashboard/patrocinadores',
                module: 'events.event.dashboard.sponsors'
            }
        ]
    },
    {
        name: 'Cadastro',
        icon: sidebarIcons.content,
        module: 'events.event.content',
        type: 'main',
        children: [
            {
                name: 'Locais',
                path: '/evento/:eventId/locais',
                module: 'events.event.content.locations'
            },
            {
                name: 'Categorias',
                path: '/evento/:eventId/categories',
                module: 'events.event.content.categories'
            },
            {
                name: 'Palestrantes',
                path: '/evento/:eventId/palestrantes',
                module: 'events.event.content.speakers'
            },
            {
                name: 'Palestras',
                path: '/evento/:eventId/palestras',
                module: 'events.event.content.talks'
            },
            {
                name: 'Patrocinadores',
                path: '/evento/:eventId/patrocinadores',
                module: 'events.event.content.sponsors'
            },
            {
                name: 'Vídeos On Demand',
                path: '/evento/:eventId/videos',
                module: 'events.event.content.on-demand'
            },
            {
                name: 'Palcos',
                path: '/evento/:eventId/palcos',
                module: 'events.event.content.stages'
            }
        ]
    },
    {
        name: 'Coleções',
        icon: sidebarIcons.collections,
        module: 'events.event.collections',
        type: 'main',
        children: [
            {
                name: 'Categorias',
                path: '/evento/:eventId/colecoes/categorias',
                module: 'events.event.collections.categories'
            },
            {
                name: 'Itens',
                path: '/evento/:eventId/colecoes/itens',
                module: 'events.event.collections.items'
            }
        ]
    },
    {
        name: 'Programa de Indicação',
        icon: sidebarIcons.store,
        module: 'events.event.member-get-member',
        type: 'main',
        children: [
            {
                name: 'Campanha',
                path: '/evento/:eventId/referal',
                exact: true,
                module: 'events.event.member-get-member.campaign'
            },

            {
                name: 'Participantes',
                path: '/evento/:eventId/referal/participantes',
                module: 'events.event.member-get-member.participants'
            }
        ]
    },
    {
        name: 'Programa de Engajamento',
        icon: sidebarIcons.gift,
        module: 'events.event.engagement',
        type: 'main',
        children: [
            {
                name: 'Campanha',
                path: '/evento/:eventId/engajamento',
                exact: true,
                module: 'events.event.engagement.campaign'
            },
            {
                name: 'Participantes',
                path: '/evento/:eventId/engajamento/participantes',
                module: 'events.event.engagement.participants'
            }
        ]
    },
    {
        name: 'Inscrições',
        icon: sidebarIcons.registrations,
        module: 'events.event.registrations',
        type: 'main',
        children: [
            {
                name: 'Inscrições',
                alias: true,
                path: '/evento/:eventId/inscricoes',
                module: 'events.event.registrations'
            }
        ]
    },
    {
        name: 'Comunicação',
        icon: sidebarIcons.communication,
        module: 'events.event.communication',
        type: 'main',
        children: [
            {
                name: 'Banners',
                path: '/evento/:eventId/banners',
                module: 'events.event.communication.banners'
            },
            {
                name: 'Notificações',
                path: '/evento/:eventId/notificacoes',
                module: 'events.event.communication.notifications'
            }
        ]
    },
    {
        name: 'Relatórios',
        icon: sidebarIcons.reports,
        module: 'events.event.reports',
        type: 'main',
        children: [
            {
                name: 'Relatórios',
                alias: true,
                path: '/evento/:eventId/reports',
                module: 'events.event.reports'
            }
        ]
    },
    {
        name: 'Formulários',
        icon: sidebarIcons.forms,
        module: 'events.event.forms',
        type: 'main',
        children: [
            {
                name: 'Inscrição',
                path: '/evento/:eventId/forms/inscricao',
                module: 'events.event.forms.registration'
            },
            {
                name: 'Complementares',
                path: '/evento/:eventId/forms/progressive',
                module: 'events.event.forms.progressive'
            },
            {
                name: 'Outros',
                path: '/evento/:eventId/forms/others',
                module: 'events.event.forms.others'
            }
        ]
    },
    {
        name: 'Page builder',
        icon: sidebarIcons.pageBuilder,
        module: 'events.event.page-builder',
        type: 'main',
        children: [
            {
                name: 'Páginas',
                path: '/evento/:eventId/paginas',
                module: 'events.event.page-builder.pages'
            },
            {
                name: 'Widgets',
                path: '/evento/:eventId/widgets',
                module: 'events.event.page-builder.widgets'
            }
        ]
    },
    {
        name: 'Transmissão',
        icon: sidebarIcons.streaming,
        module: 'events.event.streaming',
        type: 'main',
        children: [
            {
                name: 'Configurações',
                path: '/evento/:eventId/streaming/config',
                module: 'events.event.streaming.config'
            },
            {
                name: 'Certificado',
                path: '/evento/:eventId/streaming/certificado',
                module: 'events.event.streaming.certificate'
            }
        ]
    },
    {
        name: 'Bilheteria',
        icon: sidebarIcons.tickets,
        module: 'events.event.ticketing',
        type: 'main',
        children: [
            {
                name: 'Tipos de ingresso',
                path: '/evento/:eventId/ingressos',
                module: 'events.event.ticketing.tickets'
            },
            {
                name: 'Cupons de desconto',
                path: '/evento/:eventId/cupons',
                module: 'events.event.ticketing.coupon'
            },
            {
                name: 'Checkout',
                path: '/evento/:eventId/checkout/config',
                module: 'events.event.ticketing.checkout'
            }
        ]
    },
    {
        name: 'Integrações',
        icon: sidebarIcons.integrations,
        module: 'events.event.integrations',
        type: 'main',
        children: [
            {
                name: 'Conversões',
                path: '/evento/:eventId/integracoes/conversions',
                module: 'events.event.integrations.conversions'
            },
            {
                name: 'Players',
                path: '/evento/:eventId/integracoes/players',
                module: 'events.event.integrations.players'
            },
            {
                name: 'Outros',
                path: '/evento/:eventId/integracoes/others',
                module: 'events.event.integrations.others'
            },
            {
                name: 'Registros',
                path: '/evento/:eventId/integracoes/logs',
                module: 'events.event.integrations.logs'
            }
        ]
    },
    {
        name: 'FAQ',
        icon: sidebarIcons.faq,
        module: 'events.event.faq',
        type: 'main',
        children: [
            {
                name: 'Geral',
                path: '/evento/:eventId/faq/geral',
                module: 'events.event.faq.general'
            },
            {
                name: 'Tópicos',
                path: '/evento/:eventId/faq/topicos',
                module: 'events.event.faq.topics'
            },
            {
                name: 'Perguntas',
                path: '/evento/:eventId/faq/perguntas',
                module: 'events.event.faq.questions'
            }
        ]
    },
    {
        name: 'Configurações',
        icon: sidebarIcons.settings,
        module: 'events.event.configuration',
        type: 'footer',
        children: [
            {
                name: 'Informações',
                path: '/evento/:eventId/config/info',
                module: 'events.event.configuration.info'
            },
            {
                name: 'Termos de Uso',
                path: '/evento/:eventId/config/termos',
                module: 'events.event.configuration.terms'
            },
            {
                name: 'Domínio',
                path: '/evento/:eventId/config/dominio',
                module: 'events.event.configuration.domain'
            }
        ]
    }
]
