import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { CrudColumns, CrudList } from '@cms/core/components/Crud'
import { Space } from 'antd'
import { ButtonPopConfirm, ButtonLink } from '@cms/events/components/Buttons'

import { GET_USERS_REPORTEDS_ORDERABLE } from '@cms/events/graphql/queries/userReport'
import { RESOLVE_USER_REPORT } from '@cms/events/graphql/mutations/userReport'
import { BAN_USER } from '@cms/events/graphql/mutations/user'
import { removeFromList } from '@cms/core/graphql/utils'

const columns = [
    {
        title: 'Name',
        dataIndex: [''],
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node.user.avatar?.url}
                    title={obj.node.user.name}
                />
            )
        }
    },
    {
        title: 'E-mail',
        dataIndex: [],
        render: (value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    title="E-mail"
                    text={[obj.node.user.email]}
                />
            )
        }
    },
    {
        title: 'Status',
        dataIndex: ['node', 'user', 'banned'],
        width: 100,
        render: (value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    title="Status"
                    text={[obj.node.user.banned ? 'Banido' : 'Inscrito']}
                />
            )
        }
    },
    {
        title: 'Número de denúncias',
        dataIndex: ['node', 'reportsTotalCount'],
        render: (value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    title="Número de denúncias"
                    text={[obj.node.reportsTotalCount]}
                />
            )
        }
    }
]

const UsersList = () => {
    const query = useQuery(GET_USERS_REPORTEDS_ORDERABLE, {
        variables: { first: 25, orderBy: { reportsTotalCount: 'ASC' } }
    })

    const [banUser] = useMutation(BAN_USER, {
        refetchQueries: [{ query: GET_USERS_REPORTEDS_ORDERABLE }]
    })
    const [reportUserResolve] = useMutation(RESOLVE_USER_REPORT, {
        refetchQueries: [{ query: GET_USERS_REPORTEDS_ORDERABLE }]
    })

    const handledReportUser = async (variables) => {
        await banUser({ ...variables })
        await reportUserResolve({ ...variables })
        await query.refetch()
    }

    if (query.error) return <div>Error: {query.error}</div>

    const rowKey = (row) => row.node.user.id

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        align: 'right',
        render: (_, record) => {
            const id = record.node.user.id

            return (
                <Space>
                    <ButtonLink
                        id={id}
                        to={`/principal/chat/usuarios-denunciados/${id}`}
                        key={id}
                        text={'Visualizar'}
                    />
                    <ButtonPopConfirm
                        id={id}
                        key={id}
                        text={'Banir'}
                        title={'Tem certeza que deseja banir o usuário?'}
                        action={handledReportUser}
                    />
                </Space>
            )
        }
    }

    const orderByFields = [
        { label: 'Número denúncias', value: 'reportsTotalCount' }
    ]

    return (
        <>
            <CrudList
                header={{
                    title: 'Usuários Denunciados',
                    subTitle: 'Banir de forma definitiva usuário reportado'
                }}
                columns={columns}
                search={true}
                queryName={'usersAndReports'}
                hideAction={'all'}
                actionsExtra={actionsExtra}
                orderByFields={orderByFields}
                rowKey={rowKey}
                {...query}
            />
        </>
    )
}

export default UsersList
