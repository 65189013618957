import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    forwardRef,
    useMemo
} from 'react'
import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from '@ant-design/pro-layout'
import { Spin, Table, Space, Typography } from 'antd'

import _set from 'lodash/set'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'

import { useVT } from 'virtualizedtableforantd4'

import { CrudContainer, CrudListContainer } from './styles'
import { ButtonPopConfirm, ButtonLink } from '@cms/events/components/Buttons'
import { flatKeys } from '@cms/core/utils'
import CrudFilter from '../CrudFilter'
import { useCrudActions } from './crudActions'
import CrudListSearch from './Search'
import { TDCard, TDCardTitle } from '@cms/core/components/TDCards'

import { useQuery } from '@apollo/client'
import { useCheckModuleByPath } from '../../CheckModule'

const { Paragraph } = Typography

const ChildTable = (props) => {
    const { loading, data } = useQuery(props.childQuery, {
        variables: props.variables
    })

    if (loading) return <></>

    const pageInfo = data[props.queryName]?.pageInfo || {}

    const items = data[props.queryName]?.edges || []

    return (
        <>
            <Table
                summary={() => (
                    <>
                        {pageInfo.hasNextPage ? (
                            <Table.Summary.Row>
                                <Table.Summary.Cell
                                    index={undefined}
                                    colSpan={props.columns.length}
                                >
                                    <div
                                        style={{
                                            width: 100,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Spin />
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        ) : null}
                    </>
                )}
                // scroll={scrolls}
                // components={vt}
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <ChildTable
                                {...props}
                                queryName={props.queryName}
                                variables={{
                                    filter: {
                                        parent: record.node.id
                                    }
                                }}
                            />
                        )
                    },
                    rowExpandable: (record) => {
                        if (props.childQueryShouldShow) {
                            return (
                                props.childQuery &&
                                props.childQueryShouldShow(record.node)
                            )
                        }
                        return false
                    }
                }}
                rowKey={props.handleRowKey}
                // loading={scrollRollTop === 0 ? loading : false}
                loading={loading}
                columns={props.columns}
                pagination={false}
                dataSource={items}
                showHeader={false}
            />
        </>
    )
}

const CrudList = forwardRef(
    (
        {
            header: headerProp,
            data = {},
            search,
            queryName,
            fetchMore,
            onDelete,
            loading,
            columns,
            hideAction,
            allowed,
            init,
            rowKey,
            showHeader,
            orderByFields,
            orderByParams,

            actionsExtra,
            disableFor,
            transparent,
            scrollY,
            variables,

            refVT,
            borderless,
            contentHeader,
            height,

            // @deprecated
            // customFilter,
            // actionFixed,
            // refScroll,
            // reverse,
            // setQueryFilter,

            ...props
        },
        refList
    ) => {
        const {
            buttons,
            title: pageTitle,
            subTitle,
            countNames,
            ...header
        } = headerProp || {}
        const { url } = useRouteMatch()
        const ref = useRef(null)

        // TODO: refactor
        const path = '/palestras'

        const allowedWrite = useCheckModuleByPath(path, 'write')
        const allowedDelete = useCheckModuleByPath(path, 'delete')
        // end

        const [scrollRollTop, setScrollRollTop] = useState(0)

        const { items, pageInfo, totalCount } = useMemo(() => {
            return {
                items: data[queryName]?.edges || [],
                pageInfo: data[queryName]?.pageInfo || {},
                totalCount: data[queryName]?.totalCount || 0
            }
        }, [data])

        let scrolls = {
            y: scrollY || '100%',
            x: '100vw'
        }

        // if (actionFixed && ref.current && columns.length > 3) {
        //     columns[0].fixed = true
        //     scrolls.x = '100vw'
        // }

        const refetch = (...args) => {
            const el = ref?.current?.querySelector('.ant-table-body')
            if (el) el.scrollTop = 0
            setScrollRollTop(0)
            return props.refetch(...args)
        }

        const [vt] = useVT(
            () => ({
                scroll: { ...scrolls },
                onScroll: ({ isEnd, top }) => {
                    const scrollHeight =
                        ref.current?.querySelector('.ant-table-body')
                            ?.scrollHeight || 0
                    top =
                        top +
                            ref.current?.querySelector('.ant-table-body')
                                ?.offsetHeight || 0
                    const offsetTop = scrollHeight * 0.8

                    if (!pageInfo.hasNextPage || top <= scrollRollTop) return

                    if (
                        (!scrollHeight && isEnd) ||
                        (scrollHeight && top > offsetTop)
                    ) {
                        setScrollRollTop(top)
                        fetchMore({
                            variables: {
                                afterCursor: pageInfo.endCursor
                            }
                        })
                    }
                },
                ref: refVT
            }),
            [pageInfo, scrollRollTop, ref]
        )

        const actionWidth = hideAction === 'all' ? 0 : hideAction ? 120 : 150
        const extraWidth = actionsExtra ? (actionsExtra?.width || 120) + 10 : 0

        const actionsMain = {
            dataIndex: '',
            key: 'x',
            width: actionWidth + extraWidth,
            className: 'actions-main',
            // fixed: `${actionFixed ? 'right' : false}`,
            fixed: actionsExtra?.fixed ? actionsExtra?.fixed : false,
            align: actionsExtra?.align ? actionsExtra?.align : 'right',
            render: (_, record) => {
                const id = handleRowKey(record, null)
                return (
                    <Space>
                        {actionsExtra?.render
                            ? actionsExtra.render(_, record)
                            : null}

                        {hideAction !== 'edit' && hideAction !== 'all' && (
                            <ButtonLink
                                key={`edit-btn-${id}`}
                                id={id}
                                url={url}
                                path={
                                    !allowed || allowed.write
                                        ? 'editar'
                                        : 'visualizar'
                                }
                                text={
                                    !allowed || allowed.write
                                        ? 'Editar'
                                        : 'Visualizar'
                                }
                                to={undefined}
                            />
                        )}
                        {((!allowed || allowed.delete) &&
                            hideAction !== 'delete' &&
                            hideAction !== 'all') ||
                        disableFor === id ? (
                            <ButtonPopConfirm
                                key={`delete-btn-${id}`}
                                id={id}
                                action={onDelete}
                            />
                        ) : null}
                    </Space>
                )
            }
        }

        const defaultSorted = useMemo(() => {
            const OrderNormalize = flatKeys(variables?.orderBy || {})
            const defaultOrderField = Object.keys(OrderNormalize)[0] || null
            const defaultSort = defaultOrderField
                ? OrderNormalize[defaultOrderField]
                : 'DESC'

            return {
                field: defaultOrderField,
                order: defaultSort
            }
        }, [])

        const [sort, setSort] = useState(defaultSorted.order)
        const [orderField, setOrderField] = useState(defaultSorted.field)

        const handledSort = (sort) => {
            setSort(sort)
            refetch({
                orderBy: _set({}, orderField, sort)
            })
        }

        const handledOrderBy = (field) => {
            setOrderField(field)
            refetch({
                orderBy: _set({}, field, sort || 'ASC')
            })
        }

        const extra = useCrudActions({ buttons, allowed })

        const orderByParam = () => {
            return orderByParams == 'leads' ||
                orderByParams == 'talks' ||
                orderByParams == 'rewards' ? (
                <CrudFilter types={orderByParams} refetch={refetch} />
            ) : null
        }

        const handleRowKey = (o, origin) => {
            if (typeof origin === 'string') {
                return rowKey ? rowKey(o) : o.node[origin].id
            } else if (typeof origin === 'number') {
                return origin
            }
            return rowKey ? rowKey(o) : o.node.id
        }

        const visibleActions = () => {
            let visibleColumns = [...columns]

            // if (actionsExtra) {
            //     actionsExtra.className = 'actions-extra'
            //     visibleColumns = [...visibleColumns, actionsExtra]
            // }
            // if (hideAction !== 'all') {
            // }

            visibleColumns = [...visibleColumns, actionsMain]

            const hasSorter = visibleColumns.find((c) => c.sorter)
            const _sort = sort == 'ASC' ? 'ascend' : 'descend'

            if (hasSorter) {
                visibleColumns = visibleColumns.map((c, key) => {
                    if (c.sorter) {
                        return {
                            ...c,
                            key,
                            defaultSortOrder:
                                c.sorter === orderField ? _sort : null,
                            sortOrder: c.sorter === orderField ? _sort : null
                        }
                    } else {
                        return c
                    }
                })
            }

            return visibleColumns
        }
        const actionsExtraParam = orderByParam()

        const mountedColumns = visibleActions()

        useEffect(() => {
            if (init) {
                init()
            }
        }, [])

        const multiRef = useCallback((node) => {
            ref.current = node
            if (refList) {
                if (typeof refList === 'function') {
                    refList(node)
                } else if (refList.current) {
                    refList.current = node
                }
            }
        }, [])

        const searchProps = {
            refetch,
            orderByFields,
            orderField,
            handledOrderBy,
            sort,
            handledSort
        }

        const mountItemName = () => {
            const itemName = {
                plural: countNames?.plural ?? 'items',
                singular: countNames?.singular ?? 'item'
            }
            const isPlural = totalCount > 1 || totalCount == 0

            return isPlural ? itemName.plural : itemName.singular
        }

        const onChange = (pagination, filters, sorter, extra) => {
            if (extra.action !== 'sort') return

            const column = mountedColumns[sorter.columnKey]
            if (!column) return

            const _sort = sorter.order === 'ascend' ? 'ASC' : 'DESC'

            setSort(_sort)
            setOrderField(column.sorter)

            refetch({
                orderBy: _set({}, column.sorter, _sort)
            })
        }

        const CrudHeader =
            search || actionsExtraParam ? (
                <TDCardTitle>
                    <>{search && <CrudListSearch {...searchProps} />}</>
                    <Space className="crudheader-extra" key={'space-header'}>
                        <Paragraph
                            style={{
                                margin: 0,
                                paddingRight: 10,
                                textTransform: 'lowercase'
                            }}
                        >
                            {totalCount} {mountItemName()}
                        </Paragraph>
                        {actionsExtraParam}
                        {extra}
                    </Space>
                </TDCardTitle>
            ) : null

        const renderTable = ({ props, pageInfo, items }) => {
            let expandableConfig

            if (props.childQuery) {
                expandableConfig = {
                    expandedRowRender: (record) => {
                        return (
                            <ChildTable
                                {...props}
                                queryName={queryName}
                                rowKey={handleRowKey}
                                columns={mountedColumns}
                                variables={{
                                    filter: {
                                        parent: record.node.id
                                    }
                                }}
                            />
                        )
                    },
                    rowExpandable: (record) => {
                        if (props.childQueryShouldShow) {
                            return (
                                props.childQuery &&
                                props.childQueryShouldShow(record.node)
                            )
                        }
                        return false
                    }
                }
            }

            return (
                <Table
                    sortDirections={['descend', 'ascend', null]}
                    onChange={onChange}
                    summary={() => (
                        <>
                            {props.summary}
                            {pageInfo.hasNextPage ? (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell
                                        index={undefined}
                                        colSpan={mountedColumns.length}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Spin />
                                        </div>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            ) : null}
                        </>
                    )}
                    scroll={scrolls}
                    components={vt}
                    expandable={expandableConfig}
                    rowKey={handleRowKey}
                    loading={scrollRollTop === 0 ? loading : false}
                    columns={mountedColumns}
                    pagination={false}
                    dataSource={items}
                    showHeader={showHeader ? showHeader : false}
                />
            )
        }

        return (
            <CrudListContainer ref={multiRef} $height={height}>
                {contentHeader}
                {!isEmpty(header) ? (
                    <PageHeader className="crudlist-header" {...header} />
                ) : null}

                <CrudContainer
                    empty={items.length === 0}
                    className={transparent ? 'container-transparent' : null}
                    borderless={borderless}
                >
                    <TDCard title={CrudHeader}>
                        {renderTable({ props, pageInfo, items })}
                    </TDCard>
                </CrudContainer>
            </CrudListContainer>
        )
    }
)

export default CrudList
