import React from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import CollectionCategoryModal from './CollectionCategoryModal'

import { GET_COLLECTION_CATEGORIES_LIST_ORDERABLE } from '@cms/events/graphql/queries/collectionCategories'
import { DELETE_COLLECTION_CATEGORY } from '@cms/events/graphql/mutations/collectionCategories'

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name',
        render: (_, obj) => {
            return <CrudColumns onlyText text={[obj.node.name]} />
        }
    },
    {
        title: 'Descrição',
        dataIndex: '',
        render: (_, obj) => {
            return <CrudColumns onlyText text={[obj.node.description]} />
        }
    }
]

const CollectionCategoryList = () => {
    const { eventId } = useParams()

    const variables = {
        first: 25,
        eventId,
        orderBy: { createdAt: 'DESC' }
    }

    const query = useQuery(GET_COLLECTION_CATEGORIES_LIST_ORDERABLE, {
        variables
    })

    if (query.error) {
        return <div>Error</div>
    }

    const path = `/evento/${eventId}/colecoes/categorias`

    const [deleteData] = useMutation(DELETE_COLLECTION_CATEGORY, {
        refetchQueries: [
            {
                query: GET_COLLECTION_CATEGORIES_LIST_ORDERABLE,
                variables
            }
        ]
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(CollectionCategoryModal, path, { eventId })
    }, [eventId])

    const orderByFields = [
        {
            label: 'Criação',
            value: 'createdAt'
        }
    ]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Categorias',
                    subTitle: 'Crie categorias para suas coleções',
                    buttons: [
                        {
                            children: 'Nova categoria',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'collectionCategories'}
                onDelete={deleteData}
                showHeader
                {...query}
            />
        </>
    )
}

export default CollectionCategoryList
