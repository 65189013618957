import React from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import CollectionItemModal from './CollectionItemModal'

import { GET_COLLECTION_ITEMS_LIST_ORDERABLE } from '@cms/events/graphql/queries/collectionItems'
import { DELETE_COLLECTION_ITEM } from '@cms/events/graphql/mutations/collectionItems'

const columns = [
    {
        title: 'Título',
        dataIndex: ['node', 'title'],
        sorter: 'title',
        render: (_, obj) => {
            return <CrudColumns onlyText text={[obj.node.title]} />
        }
    },
    {
        title: 'Categoria',
        dataIndex: '',
        render: (_, obj) => {
            return <CrudColumns onlyText text={[obj.node.category?.name]} />
        }
    }
]

const CollectionItemList = () => {
    const { eventId } = useParams()

    const variables = {
        first: 25,
        eventId,
        orderBy: { createdAt: 'DESC' }
    }

    const query = useQuery(GET_COLLECTION_ITEMS_LIST_ORDERABLE, { variables })

    if (query.error) {
        return <div>Error</div>
    }

    const path = `/evento/${eventId}/colecoes/itens`

    const [deleteData] = useMutation(DELETE_COLLECTION_ITEM, {
        refetchQueries: [
            { query: GET_COLLECTION_ITEMS_LIST_ORDERABLE, variables }
        ]
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(CollectionItemModal, path, { eventId })
    }, [eventId])

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Itens',
                    subTitle: 'Crie itens para suas coleções',
                    countNames: {
                        singular: 'item',
                        plural: 'itens'
                    },
                    buttons: [
                        {
                            children: 'Novo item',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'collectionItems'}
                onDelete={deleteData}
                showHeader
                {...query}
            />
        </>
    )
}

export default CollectionItemList
